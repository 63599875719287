<template>
  <v-card height="400px" color="#273142">
    <v-card-title class="card-header">
      <h3 class="ml-3">Draft Orders</h3>
    </v-card-title>
    <v-card-text>
      <OrderComponent :orderStatus="orderStatus" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'draftOrders',
  components: {
    OrderComponent: () => import("@/modules/MyOrders/OrderComponent/")
  },
  data() {
    return {
      orderStatus: ['draft'],
    }
  },
}
</script>